.pomodoro {
  //background: #9f2828;
  top:1em;
  bottom:1em;
  position:relative;
  display:grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: repeat(6, 1fr);

  .task-view {
    grid-row:2/7;
    grid-column: 1;
    height:100%;
    margin-left:2em;
    border-radius: 10px;
    box-sizing: border-box;

    .exercise-tracking {
      border: 1px solid var(--main-bg);
      padding:1em;
      border-radius:10px;
      display:flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-family: monospace;
      box-sizing: border-box;
      margin-top:1em;

      .pair {

        padding:1em;
        display:grid;
        grid-template-rows: 1fr;
        grid-template-columns: 4fr 6fr;
        width:100%;

        label {
          color:black;
          grid-column: 1;
          text-align: left;
          width:100%;
        }

        input {
          grid-column: 2;
          background: none;
          outline:none;
          border:none;
        }
      }
    }

    .add-feynman, .add-term {
      border: 1px solid var(--main-bg);
      padding:1em;
      border-radius:10px;
      display:flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-family: monospace;
      box-sizing: border-box;


      input {
        margin:1em .5em;
        width:90%;
        background:none;
        border: none;
        border-bottom: 1px solid var(--main-bg);
        outline:none;
      }

      button {
        background: var(--main-bg);
        border:none;
        color:var(--link-grey);
        border-radius:4px;
        margin:1em 0 0 0;
        padding:.2em .5em;
        font-family: monospace;
        cursor: pointer;

        &:hover {
          background: purple;
        }
      }
    }
    .add-term{
      margin-top:1em;
    }

    h2 {
      color:var(--main-bg);
      font-family: monospace;
      margin-left:1em;
    }

    .view {
      background: #c9beff;
      border-radius: 10px;
      margin-bottom: 1em;
      height:calc(100% - 4em);
      width:100%;
    }
  }



  .task-log {
    font-family: monospace;
    grid-row: 1/7;
    grid-column: 2;
    //background: var(--link-grey);
    padding:1em 2em;

    h2 {
      padding-left: .4em;
      font-family: monospace;
      color:var(--main-bg);
    }

    .daily-statistics {
      border-radius: 10px;
      height:6em;
      background:white;
      margin-bottom: 1em;
      border: 1px solid var(--border-grey);
      column-gap:1em;
      justify-content: space-between;
      padding:.5em 3em;
      display:flex;
      flex-direction: row;
      grid-template-rows: 1fr;
      grid-template-columns: repeat(4, 1fr);


      .statistic-container {
        grid-row:1;
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: small;
      }
      .statistic {
        color: #60efe9;
        text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.62);
        font-size: xxx-large;
      }

      .task-count {
        grid-column: 1;
      }

      .completed-task-count {
        grid-column: 2;
      }

      .time-spent-today {
        grid-column: 3;
      }

      .total-time-spent {
        grid-column: 4;
      }
    }

    .task-options {
      width:100%;
      margin-top:1em;
      border-radius: 10px;
      box-sizing: border-box;

      select {
        background: none;
        color:var(--main-bg);
        border:none;
        width: 100%;
        font-family: monospace;
      }

      .add-reference-window {
        background: white;
        padding:1em;
        border-radius: 10px;
        border: 1px solid var(--main-bg);
      }

      .add-segment-window {
        margin-top:1em;
        background: white;
        padding:1em;
        border-radius: 10px;
        border: 1px solid var(--main-bg);
      }

      .add-duration-window {
        font-family: monospace;
        margin-top:1em;
        background: white;
        padding:1em;
        border-radius: 10px;
        border: 1px solid var(--main-bg);
      }

    }

    .add-task {
      background:white;
      border-radius: 10px;
      display:flex;
      align-items: center;
      justify-content: flex-start;
      border: 1px solid var(--border-grey);

      .add-reference, .add-duration {
        position: absolute;
        right:3em;
        cursor:pointer;


        &:hover {
          color:orange;
        }
      }

      .add-duration {
        right:4.4em;
        height:2em;
      }


      h1 {
        display: inline-block;
        margin: 0 0 0 .5em;
        position: absolute;
        color: #8572e1;
      }

      input {
        display:inline-block;
        height:100%;
        border:none;
        outline-color: var(--blue);
        //outline: 2px solid var(--blue);
        padding:1em .5em 1em 4em;
        width:100%;
        border-radius: 10px;
      }


    }

    .task-display {
      margin-top: 2em;

      .heading {
        font-family: monospace;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right:1em;

        h2 {
          display: inline-block;
          color:var(--main-bg);
          margin-bottom:.5em;
        }

        .show-completed-tasks {
          display: inline-block;

          input {
            &:checked {
              fill:purple;

              background: #c9beff;
              color:#c9beff;
            }
          }
        }
      }


      .tasks {
        background: var(--main-bg);
        margin-top:1em;
        height:55vh;
        border-radius: 10px;
        padding:1em;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid var(--border-grey);
        overflow-y:scroll;


      .task {
        padding:.5em 1em;
        font-family: monospace;
        background: white;
        border-radius:8px;
        width:calc(100% );
        box-sizing: border-box;
        display:flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom:1em;
        cursor:pointer;
        position: relative;

        .delete-task {
          background: none;
          position: absolute;
          right:1em;
          border-radius: 4px;
          color:#c9beff;
        }

        &.completed {
          background: #2d2d2d;
        }

        &.active-task {
          background: #a54070;
        }

        input {
          margin-right:1em;
        }

        &:hover {
        }

      }


      }
    }



  }

}



.pomodoro-clock {
  grid-row: 1/2;
  grid-column: 1;
  margin:1em 0 1em 2em;
  border-radius: 10px;


  h2 {
    color:var(--main-bg);
    font-family: monospace;
  }

  .clock {
    padding:1em;
    background: #c9beff;
    border-radius: 10px;
    border: 1px solid var(--main-bg);
    box-sizing: border-box;
    width:100%;

    .time-running {
      font-size: xxx-large;
    }

    button {
      background: var(--main-bg);
      color:#c9beff;
      border:none;
      border-radius: 4px;
      margin-right:.5em;
      font-family:monospace;

      &:disabled {
      background: var(--border-grey);
        color:var(--link-grey);
      }

    }
  }

  .pomodoro-settings {
    padding:1em;
  }


}


@media (max-width: 1000px) and (min-width: 0px) {
  .pomodoro {
    display:grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows:auto;
    background: #c9beff;
    min-height: 100%;

    .statistic-container {
      font-size: xx-small;
      white-space: nowrap; /* Prevent text from wrapping */
      overflow: hidden; /* Hide overflow */
      text-overflow: ellipsis; /* Show ellipsis (...) where text overflows */

      .statistic {
        font-size: medium;
      }
    }

    .task-view {
      grid-row:6/16;
      grid-column: 1/13;
      margin:0;
      width:100%;

      .add-feynman, .add-term {
        margin: 1em 2em 2em 2em;
      }

    }


    .task-log {
      grid-column: 1/13;
      grid-row:2/4;

      .task-display {
      }
    }

    .pomodoro-clock {
      grid-row:1;
      grid-column: 1/13;
      margin-right:2em;

    }

  }

}