.article-display {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  height:70vh;
  display:flex;
  align-items: center;
  justify-content: center;
}

.display {
  display: block;
}

.close-pdf {
  position: absolute;
  top:1em;
  right:1em;
  font-family: monospace;
  color:white;
  border:none;
  background: none;
  cursor: pointer;
}