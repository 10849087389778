.cards-workspace {
  position: absolute;
  top:1em;
  left:1em;
  right:1em;
  bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;

  .add-img-window {
    position: absolute;
    background: var(--main-bg);
    border: 1px solid var(--border-grey);
    padding:2em;
    border-radius:10px;
    box-sizing: border-box;

    input {
      border-radius: 5px;
    }

    .upload-img {
      color:var(--link-grey);
      font-family: monospace;
    }

    .cls-window {
      position: absolute;
      top:.5em;
      right:.5em;
      font-family: monospace;
      color:var(--border-grey);
      border:none;
      background: none;
      cursor: pointer;
      &:hover {
        color:var(--link-grey);
      }
    }

  }

  .arrow-cards {
    position: absolute;
    cursor:pointer;

    &:hover {
      fill: var(--link-grey);
    }
    &.left {
      left:3em;
    }

    &.right {
      right:3em;
    }
  }

  .statistics {
    color: var(--border-grey);
    position: absolute;
    bottom:0;
    right:0;
    font-family: monospace;
  }

  .submit-card {
    position: absolute;
    bottom:2em;

    button {
      border: 1px solid var(--border-grey);
      background: none;
      border-radius: 4px;
      color:var(--border-grey);
      font-family: monospace;
      cursor:pointer;

      &:hover {
        color:var(--link-grey);
        border-color:var(--link-grey);
      }
    }
  }


  .card-display {
    width:80%;
    height:80%;
    grid-template-rows: 20% 80%;
    display:grid;
    grid-template-columns: 10% 80% 10%;
    box-sizing: border-box;


    .card-metadata {
      grid-row:1;
      grid-column: 2;
      background: #181818;
      margin-bottom:1em;
      border-radius: 10px;
      height:fit-content;
      border:1px solid var(--border-grey);
      padding:1em;
      position: relative;

      .flip-card {
        position: absolute;
        right:2.9em;
        top:1em;

        svg{
          &:hover {
            fill:var(--blue);
          }
        }
      }

      .add-img {
        position: absolute;
        right:4.5em;
        top:1em;
        cursor:pointer;

        svg{
          &:hover {
            fill:var(--blue);
          }
        }
      }

      .add-card {
        position: absolute;
        right:1em;
        top:1em;

        button {
          background: var(--blue);
          color:var(--link-grey);
          border:none;
          border-radius: 4px;
          cursor:pointer;

          &:hover {
            color:var(--main-bg);
            background: var(--link-grey);
          }
        }

      }

      select {
        color: #005fa5;
        border:none;
        background: var(--main-bg);
        font-family: monospace;
        margin:.4em .4em;

      }

      label {
        font-weight:700;
        margin-right:1em;
        color:var(--link-grey);
      }

      input {
        font-family:monospace;
        background: none;
        color: #c9beff;
        border:none;
        width:80%;
        outline:none;
      }
    }

    .card {
      grid-column: 2;
      grid-row:2;
      //background: var(--blue);
      width:100%;
      border-radius: 10px;
      padding:1em;
      box-sizing: border-box;

      .QA {
        width:100%;
        height:60%;
        display:flex;
        align-items: flex-start;
        justify-content: center;
        text-align: center;

        .img-container {
          position: absolute;
          bottom:0;
          right:0;
          img {
            max-width: 18em;
          }
        }

        textarea {
          width:100%;
          resize:none;
          height:1.2em;
          padding: 0 2em;
          background: none;
          border:none;
          max-height: 30%;
          outline:none;
          text-align: center;
          color:var(--link-grey);
        }
      }
    }
  }


}