.sr-alarm {
  border-radius: 15px;
  position: fixed;
  background: linear-gradient(60deg, #9ef3ad, #7cf3c9);
  color: #1ba49e;
  z-index: 15;
  bottom: 2em;
  right: 1em;
  font-family: monospace;
  height: 3em;
  width: 10em;
  padding: 1em;
  cursor: pointer;
  box-shadow: 1px 2px 2px rgba(28, 28, 28, 0.42);
}

