.css-pen-display {
  position: absolute;
  border-top: 1px solid var(--border-grey);
  top: 6em;
  left: 0;
  right: 0;
  bottom: 0;
  background: #232323;
}

