@import url("https://fonts.googleapis.com/css2?family=Bitter:ital,wght@1,300&family=Gloock&family=Inconsolata:wght@300&family=Inter:wght@300&family=Lobster&family=Lora:ital,wght@0,400;1,600&family=Merriweather:ital@1&family=Montserrat:wght@600&family=Nunito:wght@300&family=Playfair+Display:wght@500&family=Poppins&family=Roboto+Mono:wght@300&family=Roboto+Slab:wght@100..900&family=Roboto:wght@100&family=Rubik:wght@300&family=Space+Grotesk:wght@500&display=swap");
html, body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#root {
  min-height: 100%;
  width: 100%;
  background: #181818;
}

:root {
  --card_width: 8em;
  --card_border_radius: 16px;
  --row_increment: 10px;
  --card: 45;
  --border-grey: #3d3d3d;
  --link-grey: #cecece;
  --main-bg: #181818;
  --blue: #3217a4;
}

html, body {
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
}

.app {
  min-height: 100%;
  overflow: hidden;
}

