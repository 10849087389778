.spaced-repetition {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: var(--main-bg);

  .card-display {
    display:flex;
    align-items: center;
    justify-content: center;
    height:90%;

    .card-container {
      perspective: 1000px;
      cursor: pointer;
    }

    .card {
      width: 600px; /* Adjust based on your needs */
      height: 500px; /* Adjust based on your needs */
      transition: transform 0.6s;
      transform-style: preserve-3d;
      position: relative;
    }

    .card.flipped {
      transform: rotateY(180deg);
    }

    .card-front, .card-back {
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: 1px solid #ccc; /* Optional */
      border-radius: 5px; /* Optional */
    }

    .card-front {
      background-color: white; /* Your front side color */
    }

    .card-back {
      background-color: #f1f1f1; /* Your back side color */
      transform: rotateY(180deg);
    }
  }
}

.keyboard-shortcut {
  color:white;
  font-family: monospace;
  display:flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  div {
    margin:2em;
    font-style: oblique;
    color:grey;
  }
}
@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.shake-animation {
  animation: shake 0.5s;
}


.command-card, .keyboard-card {
  display:flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  row-gap: 1em;
  color:var(--link-grey);


  input {
    background: #0c0c0c;
    padding:.5em 1em;
    border-radius: 4px;
    width:80vw;
    border:none;
    color: #78af09;
    outline:none;
    font-family: monospace;
  text-align: center;
  }
}

.keyboard-card {
  textarea {
    text-align: center;
    background: none;
    border:none;
    color:white;
    outline:none;
    resize: none;
    height:10vh;
    overflow-x:hidden;
    width:50vw;
  }
}

.no-cards-left {
  font-family: monospace;
  color: #c9beff;
}