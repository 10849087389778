

.createMode {
  background: linear-gradient(60deg,#3217a4, var(--main-bg));
  position: absolute;
  top:3em;
  bottom:0;
  width:100%;
  overflow:hidden;
  border-top:1px solid var(--border-grey);
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .feynman-method {
    padding:2em;


    .heading {
      color:var(--border-grey);
      margin-bottom: 1em;
    }

    .level-arrow {
      z-index: 11;
    }

    .level-arrow.left {
      position: absolute;
      fill: white;
      bottom:1em;
      left:1em;
    }

    .level-arrow.right {
      position: absolute;
      bottom: 1em;
      right:1em;
    }

    .question {


      .search-results {
        background: var(--link-grey);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding:.5em;
        max-height: 10em;
        overflow-y: scroll;
        color:var(--main-bg);
        font-family: monospace;
        position: absolute;
        z-index:10;
        width: calc(100% - 7.5em);
        margin: -.5em 1em 0;

        .search-suggestion {
          &:hover {
            background:var(--blue);
            color:var(--link-grey);
          }
        }
      }

      input {
        margin: 1em;
        width:calc(100% - 2em);
        background: none;
        border:none;
        outline: none;
        border-bottom: 1px solid var(--border-grey);
        font-family: monospace;
        color:var(--link-grey);
        text-align: center;
      }
    }

    .levels {
      position: relative;
      .heading {
        margin-top: 2em;

        button {
          background:none;
          border:none;
          color:var(--border-grey);
          cursor:pointer;
          font-family: monospace;

          &:hover {
            color:var(--link-grey);
          }
        }
      }

      .container {
        height:50em;
        position: relative;
        width:calc(100%);
        overflow-y:scroll;
        padding-bottom: 1em;

        div:has(button) {
          display: inline-block;
        }

        button {
          background:none;
          border:none;
          color:var(--border-grey);
          cursor:pointer;
          font-family: monospace;
          padding:0 1em;
          &:hover {
            color:var(--link-grey);
          }

        }
      }

      .digit {
        background: var(--link-grey);
        display: inline-block;
        border-radius: 10px;
        padding:.2em .5em;
        font-family: monospace;
      }

      .level {
        background: linear-gradient(60deg, purple, #3a23a8);
        margin:1em 0;
        border-radius: 10px;
        column-gap:.2em;
        padding:1em 0;
        position: relative;
        height:80%;
        width: calc(100% );
        row-gap: 1em;
        overflow:hidden;
        box-sizing: border-box;

        .qas {
          overflow-y:scroll;
          width:100%;
          display:flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          position: absolute;
          height:calc(100% - 10em);
        }

        .heading {
          //color:var(--link-grey);
          margin:0;
          margin-left:2em;
          z-index: 44;
          font-family: monospace;
        }

        .description {
          width:calc(100% - 4em);
          margin:0 1em 1em 1em;
          display:flex;
          align-items: center;
          justify-content: center;
          backdrop-filter: blur(20px);
          background: rgba(255, 255, 255, 0.07);
          border-radius: 10px;
          box-shadow: 1px 3px 3px rgba(24, 24, 24, 0.11);
          height: 8em;
          padding:.5em 1em;

          textarea {
            width:calc(100% - 1em);
            outline:none;
            background:none;
            border:none;
            resize:none;
            overflow-y:hidden;
            height:calc(100% - 2em);
            color:var(--link-grey);
            padding:.5em;
          }
        }

        .qa {
          background: var(--main-bg);
          border-radius: 10px;
          display:flex;
          flex-direction: column;
          row-gap: 1em;
          width:calc(100% - 2em);
          box-sizing: border-box;
          padding:1em;
          box-shadow: 1px 3px 3px rgba(24, 24, 24, 0.11);

          .qa-sig {
            color:  #3a23a8;
          }


          textarea {
            background: none;
            color:var(--link-grey);
            border:none;
            outline:none;
            resize: none;
            overflow-x:clip;
            width:100%;
            font-family: monospace;
          }

        }

      }
    }
  }

}

.notepad {
  height:70%;
  background: var(--main-bg);
  width: 80%;
  border-radius: 10px;
  overflow:hidden;
  position: relative;
  //border: 1px solid var(--border-grey);

}

.notepad-settings {
  background: rgba(24, 24, 24, 0.58);
  backdrop-filter: blur(20px);
  height:fit-content;
  padding:1em;
  width:80%;
  margin-bottom:1em;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  flex-wrap: wrap;
  border: 1px solid var(--border-grey);

  .reset {
    cursor:pointer;
    &:hover {
      fill:var(--link-grey);
    }
  }

  select {
    color: #3217a4;
    border:none;
    background: var(--main-bg);
    font-family: monospace;
    margin:.4em .4em;

  }

  input {
    background: var(--main-bg);
    color:var(--blue);
    outline:none;
    font-family: monospace;
    border:none;
    width:fit-content;
    margin-left:1em;
    padding-left:1em;
    margin-right:.5em;
  }


}

.submit-feynman {
  position: absolute;
  bottom:1.3em;
  left:0;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;

  button {
    border-radius:10px;
    border: 1px solid var(--border-grey);
    color:var(--border-grey);
    background: none;
    padding:.2em 1em;
    font-family: monospace;
    cursor:pointer;

    &:hover {
      color:var(--link-grey);
      border-color:var(--link-grey);
    }
  }
}