.horizontal-menu {
  z-index: 44;
  background: var(--main-bg);
  display: flex;
  position: sticky;
  height: 3em;
  justify-content: center;
  align-items: center;
  overflow-x: scroll;
  text-align: center;
  flex-direction: row;
  scrollbar-width: none;
  border-top: 1px solid var(--border-grey);
  border-bottom: 1px solid var(--border-grey);
}

.link-section-menu {
  display: flex;
  height: 3em;
  justify-content: center;
  align-items: center;
  scrollbar-width: none;
  color: #383838;
  margin-left: 2em;
}

.button-section-menu {
  grid-row: 1;
  grid-column: 2/4;
  display: flex;
  justify-content: right;
  margin-right: 1em;
}

.horizontal-link {
  margin: 0.2em 3em;
  max-width: max-content;
  flex-shrink: 0;
  display: block;
  white-space: nowrap;
  text-decoration: none;
  color: var(--link-grey);
  text-underline: none;
  font-family: "JetBrains Mono Light", monospace;
  font-size: x-small;
}
.horizontal-link:hover {
  color: #b4ffd2;
}

.active-link {
  color: #b4ffd2;
}

