.menu-main {
  width: 100vw;
  justify-content: center;
  /* Center items horizontally */
  flex-wrap: nowrap;
  /* Prevent wrapping to ensure scrolling */
  overflow-x: scroll;
  /* Allow horizontal scrolling */
  white-space: nowrap;
  /* Prevents the divs from wrapping onto multiple lines */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  height: 3em;
  overflow-y: hidden;
  align-items: center;
  display: flex;
  position: sticky;
}
.menu-main .menu-link {
  margin: 0 10px;
  /* Space between items */
  display: inline-flex;
  /* Inline-flex to keep the div inline but also flex */
  align-items: center;
  /* Center items vertically */
  justify-content: center;
  min-width: 120px;
  font-family: monospace;
}
.menu-main .menu-link a {
  display: block;
  /* Makes the link fill the div for better clickability */
  padding: 10px;
  text-decoration: none;
  color: var(--link-grey);
}
.menu-main .menu-link:hover {
  color: #7cf3c9;
  font-style: oblique;
}

@keyframes slideIn {
  to {
    transform: translateX(0);
  }
}
a.active-path {
  color: #97afad;
  font-style: oblique;
}
a.active-path:hover {
  color: #60efe9;
}

