.display {
  display: grid;
  grid-template-rows: 3em auto;
  grid-template-columns: 55% 45%;
  column-gap: 1em;
  left: 0;
  right: 0;
  overflow: visible;
  background: linear-gradient(60deg, #0b3848, var(--main-bg));
}

.reference-display {
  grid-row: 2/3;
  grid-column: 1/3;
  position: relative;
  padding: 1em 0 2em 0;
  display: grid;
  height: 84vh;
  grid-template-columns: repeat(auto-fill, var(--card_width));
  grid-auto-rows: 0.001em;
  column-gap: 0.6em;
  justify-content: center;
  overflow-y: scroll;
  row-gap: 2px;
  border-bottom: 1px solid var(--border-grey);
  margin-top: 1em;
}

.tracker-display {
  grid-column: 2;
  display: grid;
  grid-template-rows: 3em auto;
  grid-template-columns: auto;
  height: 84vh;
  width: calc(100% - 2em);
  grid-row: 2/3;
  margin-top: 2em;
}

.cover {
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
}

.img-border {
  border-radius: 10px;
  border: 1.5px solid #3d3d3d;
  box-sizing: border-box;
  z-index: 4;
  height: 100%;
  width: 100%;
  position: absolute;
}

.tracker-reference-block {
  height: fit-content;
  position: relative;
  width: fit-content;
  border-radius: 10px;
  display: flex;
  grid-row-end: span 1;
}
.display-menu {
  position: absolute;
  background: rgba(28, 27, 27, 0.82);
  height: 100%;
  width: 100%;
  z-index: 5;
  border-radius: 10px;
}

.edit-icon {
  right: 0;
  margin: 1.2em 1em;
  position: absolute;
  z-index: 7;
}
.edit-icon:hover {
  fill: #aeff6b;
  cursor: pointer;
}

.tag-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 6;
  width: 100%;
  flex-direction: column;
  height: 100%;
}

.menu-tag {
  border-radius: 15px;
  font-family: monospace;
  background: #e1e1e1;
  padding: 0.2em 1em;
  font-size: x-small;
  display: block;
  margin: 0.5em;
  cursor: pointer;
}
.menu-tag.inactive {
  color: black;
}
.menu-tag.active {
  background: linear-gradient(45deg, #c4f18e, #60efe9);
  color: #094154;
}
.menu-tag.archived {
  background: #939393;
  color: #2d2d2d;
}

