.pomodoro {
  top: 1em;
  bottom: 1em;
  position: relative;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: repeat(6, 1fr);
}
.pomodoro .task-view {
  grid-row: 2/7;
  grid-column: 1;
  height: 100%;
  margin-left: 2em;
  border-radius: 10px;
  box-sizing: border-box;
}
.pomodoro .task-view .exercise-tracking {
  border: 1px solid var(--main-bg);
  padding: 1em;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: monospace;
  box-sizing: border-box;
  margin-top: 1em;
}
.pomodoro .task-view .exercise-tracking .pair {
  padding: 1em;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 4fr 6fr;
  width: 100%;
}
.pomodoro .task-view .exercise-tracking .pair label {
  color: black;
  grid-column: 1;
  text-align: left;
  width: 100%;
}
.pomodoro .task-view .exercise-tracking .pair input {
  grid-column: 2;
  background: none;
  outline: none;
  border: none;
}
.pomodoro .task-view .add-feynman, .pomodoro .task-view .add-term {
  border: 1px solid var(--main-bg);
  padding: 1em;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: monospace;
  box-sizing: border-box;
}
.pomodoro .task-view .add-feynman input, .pomodoro .task-view .add-term input {
  margin: 1em 0.5em;
  width: 90%;
  background: none;
  border: none;
  border-bottom: 1px solid var(--main-bg);
  outline: none;
}
.pomodoro .task-view .add-feynman button, .pomodoro .task-view .add-term button {
  background: var(--main-bg);
  border: none;
  color: var(--link-grey);
  border-radius: 4px;
  margin: 1em 0 0 0;
  padding: 0.2em 0.5em;
  font-family: monospace;
  cursor: pointer;
}
.pomodoro .task-view .add-feynman button:hover, .pomodoro .task-view .add-term button:hover {
  background: purple;
}
.pomodoro .task-view .add-term {
  margin-top: 1em;
}
.pomodoro .task-view h2 {
  color: var(--main-bg);
  font-family: monospace;
  margin-left: 1em;
}
.pomodoro .task-view .view {
  background: #c9beff;
  border-radius: 10px;
  margin-bottom: 1em;
  height: calc(100% - 4em);
  width: 100%;
}
.pomodoro .task-log {
  font-family: monospace;
  grid-row: 1/7;
  grid-column: 2;
  padding: 1em 2em;
}
.pomodoro .task-log h2 {
  padding-left: 0.4em;
  font-family: monospace;
  color: var(--main-bg);
}
.pomodoro .task-log .daily-statistics {
  border-radius: 10px;
  height: 6em;
  background: white;
  margin-bottom: 1em;
  border: 1px solid var(--border-grey);
  column-gap: 1em;
  justify-content: space-between;
  padding: 0.5em 3em;
  display: flex;
  flex-direction: row;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(4, 1fr);
}
.pomodoro .task-log .daily-statistics .statistic-container {
  grid-row: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: small;
}
.pomodoro .task-log .daily-statistics .statistic {
  color: #60efe9;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.62);
  font-size: xxx-large;
}
.pomodoro .task-log .daily-statistics .task-count {
  grid-column: 1;
}
.pomodoro .task-log .daily-statistics .completed-task-count {
  grid-column: 2;
}
.pomodoro .task-log .daily-statistics .time-spent-today {
  grid-column: 3;
}
.pomodoro .task-log .daily-statistics .total-time-spent {
  grid-column: 4;
}
.pomodoro .task-log .task-options {
  width: 100%;
  margin-top: 1em;
  border-radius: 10px;
  box-sizing: border-box;
}
.pomodoro .task-log .task-options select {
  background: none;
  color: var(--main-bg);
  border: none;
  width: 100%;
  font-family: monospace;
}
.pomodoro .task-log .task-options .add-reference-window {
  background: white;
  padding: 1em;
  border-radius: 10px;
  border: 1px solid var(--main-bg);
}
.pomodoro .task-log .task-options .add-segment-window {
  margin-top: 1em;
  background: white;
  padding: 1em;
  border-radius: 10px;
  border: 1px solid var(--main-bg);
}
.pomodoro .task-log .task-options .add-duration-window {
  font-family: monospace;
  margin-top: 1em;
  background: white;
  padding: 1em;
  border-radius: 10px;
  border: 1px solid var(--main-bg);
}
.pomodoro .task-log .add-task {
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid var(--border-grey);
}
.pomodoro .task-log .add-task .add-reference, .pomodoro .task-log .add-task .add-duration {
  position: absolute;
  right: 3em;
  cursor: pointer;
}
.pomodoro .task-log .add-task .add-reference:hover, .pomodoro .task-log .add-task .add-duration:hover {
  color: orange;
}
.pomodoro .task-log .add-task .add-duration {
  right: 4.4em;
  height: 2em;
}
.pomodoro .task-log .add-task h1 {
  display: inline-block;
  margin: 0 0 0 0.5em;
  position: absolute;
  color: #8572e1;
}
.pomodoro .task-log .add-task input {
  display: inline-block;
  height: 100%;
  border: none;
  outline-color: var(--blue);
  padding: 1em 0.5em 1em 4em;
  width: 100%;
  border-radius: 10px;
}
.pomodoro .task-log .task-display {
  margin-top: 2em;
}
.pomodoro .task-log .task-display .heading {
  font-family: monospace;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 1em;
}
.pomodoro .task-log .task-display .heading h2 {
  display: inline-block;
  color: var(--main-bg);
  margin-bottom: 0.5em;
}
.pomodoro .task-log .task-display .heading .show-completed-tasks {
  display: inline-block;
}
.pomodoro .task-log .task-display .heading .show-completed-tasks input:checked {
  fill: purple;
  background: #c9beff;
  color: #c9beff;
}
.pomodoro .task-log .task-display .tasks {
  background: var(--main-bg);
  margin-top: 1em;
  height: 55vh;
  border-radius: 10px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid var(--border-grey);
  overflow-y: scroll;
}
.pomodoro .task-log .task-display .tasks .task {
  padding: 0.5em 1em;
  font-family: monospace;
  background: white;
  border-radius: 8px;
  width: calc(100% );
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1em;
  cursor: pointer;
  position: relative;
}
.pomodoro .task-log .task-display .tasks .task .delete-task {
  background: none;
  position: absolute;
  right: 1em;
  border-radius: 4px;
  color: #c9beff;
}
.pomodoro .task-log .task-display .tasks .task.completed {
  background: #2d2d2d;
}
.pomodoro .task-log .task-display .tasks .task.active-task {
  background: #a54070;
}
.pomodoro .task-log .task-display .tasks .task input {
  margin-right: 1em;
}
.pomodoro-clock {
  grid-row: 1/2;
  grid-column: 1;
  margin: 1em 0 1em 2em;
  border-radius: 10px;
}
.pomodoro-clock h2 {
  color: var(--main-bg);
  font-family: monospace;
}
.pomodoro-clock .clock {
  padding: 1em;
  background: #c9beff;
  border-radius: 10px;
  border: 1px solid var(--main-bg);
  box-sizing: border-box;
  width: 100%;
}
.pomodoro-clock .clock .time-running {
  font-size: xxx-large;
}
.pomodoro-clock .clock button {
  background: var(--main-bg);
  color: #c9beff;
  border: none;
  border-radius: 4px;
  margin-right: 0.5em;
  font-family: monospace;
}
.pomodoro-clock .clock button:disabled {
  background: var(--border-grey);
  color: var(--link-grey);
}
.pomodoro-clock .pomodoro-settings {
  padding: 1em;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .pomodoro {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    background: #c9beff;
    min-height: 100%;
  }
  .pomodoro .statistic-container {
    font-size: xx-small;
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Hide overflow */
    text-overflow: ellipsis;
    /* Show ellipsis (...) where text overflows */
  }
  .pomodoro .statistic-container .statistic {
    font-size: medium;
  }
  .pomodoro .task-view {
    grid-row: 6/16;
    grid-column: 1/13;
    margin: 0;
    width: 100%;
  }
  .pomodoro .task-view .add-feynman, .pomodoro .task-view .add-term {
    margin: 1em 2em 2em 2em;
  }
  .pomodoro .task-log {
    grid-column: 1/13;
    grid-row: 2/4;
  }
  .pomodoro .pomodoro-clock {
    grid-row: 1;
    grid-column: 1/13;
    margin-right: 2em;
  }
}

