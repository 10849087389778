.search-bar {
  padding: 0.1em 3.5em;
  display: flex;
  height: 3em;
  justify-content: right;
  background: #181818;
  align-items: center;
  border-bottom: 1px solid var(--border-grey);
  border-top: 1px solid var(--border-grey);
  position: relative;
  color: var(--link-grey);
  grid-column: 1/3;
}
.search-bar input {
  outline: none;
  width: 15em;
  border-radius: 15px;
  padding: 0.2em 2em;
  border: 1px solid var(--border-grey);
  font-family: "Droid Sans", serif;
  font-style: oblique;
  padding-right: 30px;
  color: var(--link-grey);
  background: none;
}
.search-bar .search-icon {
  position: absolute;
  right: 5em;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--link-grey);
}

