.form {
  padding: 1em;
  position: absolute;
  width: 94vw;
  height: 65vh;
  display: grid;
  grid-template-rows: 4fr 0.5fr 0.5fr;
  grid-template-columns: 1fr;
}

.form-pair {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
  margin: 1em;
  grid-column: 1/3;
}

label {
  font-family: monospace;
  grid-column: 1;
  color: var(--link-grey);
  font-size: small;
}

.form input {
  grid-column: 2/4;
  color: var(--link-grey);
  font-size: small;
  font-family: monospace;
  background: none;
  border: none;
  border-bottom: 1px dashed var(--border-grey);
  outline: none;
}
.form select {
  color: var(--link-grey);
  background: none;
  border: none;
  outline: none;
  font-family: monospace;
}
.form .cover-container {
  grid-column: 2;
  display: flex;
  align-items: center;
  justify-content: right;
  overflow: hidden;
  margin-top: 3em;
  margin-left: 2em;
}
.form .cover-container img {
  max-width: 80%;
  object-fit: contain;
}
.form .content-container {
  grid-column: 2/4;
  margin: 0 0 0 0;
  max-height: 45vh;
  position: relative;
  overflow: scroll;
}
.form .content {
  margin: 2em 0;
}
.form .form-segment {
  grid-row: 1;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
}
.form .form-segment label {
  grid-column: 1;
  padding-left: 4em;
}
.form .form-segment .segment-input {
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form .form-segment .segment-input input {
  width: 80%;
}
.form .btn-container {
  grid-column: 1/3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}

.arrow-container.container-right {
  right: 0;
  bottom: 3em;
  grid-column: 2;
  display: flex;
  justify-content: right;
}

.arrow-container.container-left {
  left: 0;
  bottom: 3em;
  grid-column: 1;
  display: flex;
  justify-content: left;
}

.arrow {
  fill: var(--link-grey);
}
.arrow:hover {
  cursor: pointer;
}

.btn {
  bottom: 0;
  left: 0;
  right: 0;
  grid-row: 3;
}

.btn button {
  color: var(--link-grey);
  background: var(--main-bg);
  font-family: monospace;
  padding: 0.2em 2em;
  border: 0.01em solid var(--link-grey);
  border-radius: 10px;
}
.btn button:hover {
  background: #c9beff;
  color: var(--main-bg);
  cursor: pointer;
}

.form-main {
  grid-row: 1;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr;
  padding: 1em;
}

.container-pager {
  grid-row: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.form-content {
  grid-column: 1;
  margin: 1em;
}

.segment {
  margin-bottom: 2em;
}

input.cover {
  border-radius: 0;
}

.btn-container {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.btn-container button {
  display: inline-flex;
}

