


.explorer {
  position:absolute;
  top:0;
  margin: 2em 2em;
  //background: purple;
  height:calc(100% - 4em);
  width:calc(100%-  4em);
  overflow:hidden;
}

.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:2em;
  margin-top:5em;
}

.text-container {
  display:grid;
  grid-template-columns: 3fr 4fr 2fr;
  grid-template-rows:auto;
  overflow: hidden;

}

.text-header {
  grid-row:1;
  grid-column: 2/3;
  color:white;
  font-size: xx-large;
  padding-left:2em;
}

.text-main {
  grid-row: 2;
  grid-column: 2/3;
  margin-top:2em;
  color:white;
  //text-align: justify;
  font-size: small;
  font-family: monospace;
  line-height: 1.4em;
  overflow-y:scroll;
  height:50em;
  padding: 1em;
  -ms-overflow-style: none;
  scrollbar-width:none;

  &::-webkit-scrollbar {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 0;
}

#keyword-graph-container {
  position:absolute;
  left:2em;
  height:100%;
  width:30%;
}