.reference-tracker {
  position:absolute;
  top:3em;
  left:0;
  right:0;
  //background: #d2d2d2;
  overflow:hidden;
  height:calc(100vh - 4em);
  border-bottom: 1px solid var(--border-grey);
  border-top: 1px solid var(--border-grey);

  .horizontal-menu {

  }
}






.reference-menu {
  position:absolute;
}

.reference-creation {
  max-height:85vh;
  width:100%;
  position: absolute;
}

.status-tag {
  border-radius: 15px;
}
.title {
  margin:0;
}

.subtitle {
  margin:0;
  font-size: large;
}

.inactive {
  background: linear-gradient(45deg, grey, #939393);
  color: #e3e3e3;
}


.update-status-window {
  position: absolute;
  background: purple;
  margin: auto auto;
}


@media screen and (max-width: 450px) {
  .reference-tracker {
    margin:0;
  }
}