.workspace {
  position: absolute;
  top: 6em;
  left: 0;
  right: 0;
  border-top: 1px solid var(--border-grey);
  background: #c9beff;
  bottom: 0;
  overflow-y: scroll;
}

