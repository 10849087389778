.cards-workspace {
  position: absolute;
  top: 1em;
  left: 1em;
  right: 1em;
  bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cards-workspace .add-img-window {
  position: absolute;
  background: var(--main-bg);
  border: 1px solid var(--border-grey);
  padding: 2em;
  border-radius: 10px;
  box-sizing: border-box;
}
.cards-workspace .add-img-window input {
  border-radius: 5px;
}
.cards-workspace .add-img-window .upload-img {
  color: var(--link-grey);
  font-family: monospace;
}
.cards-workspace .add-img-window .cls-window {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-family: monospace;
  color: var(--border-grey);
  border: none;
  background: none;
  cursor: pointer;
}
.cards-workspace .add-img-window .cls-window:hover {
  color: var(--link-grey);
}
.cards-workspace .arrow-cards {
  position: absolute;
  cursor: pointer;
}
.cards-workspace .arrow-cards:hover {
  fill: var(--link-grey);
}
.cards-workspace .arrow-cards.left {
  left: 3em;
}
.cards-workspace .arrow-cards.right {
  right: 3em;
}
.cards-workspace .statistics {
  color: var(--border-grey);
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: monospace;
}
.cards-workspace .submit-card {
  position: absolute;
  bottom: 2em;
}
.cards-workspace .submit-card button {
  border: 1px solid var(--border-grey);
  background: none;
  border-radius: 4px;
  color: var(--border-grey);
  font-family: monospace;
  cursor: pointer;
}
.cards-workspace .submit-card button:hover {
  color: var(--link-grey);
  border-color: var(--link-grey);
}
.cards-workspace .card-display {
  width: 80%;
  height: 80%;
  grid-template-rows: 20% 80%;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  box-sizing: border-box;
}
.cards-workspace .card-display .card-metadata {
  grid-row: 1;
  grid-column: 2;
  background: #181818;
  margin-bottom: 1em;
  border-radius: 10px;
  height: fit-content;
  border: 1px solid var(--border-grey);
  padding: 1em;
  position: relative;
}
.cards-workspace .card-display .card-metadata .flip-card {
  position: absolute;
  right: 2.9em;
  top: 1em;
}
.cards-workspace .card-display .card-metadata .flip-card svg:hover {
  fill: var(--blue);
}
.cards-workspace .card-display .card-metadata .add-img {
  position: absolute;
  right: 4.5em;
  top: 1em;
  cursor: pointer;
}
.cards-workspace .card-display .card-metadata .add-img svg:hover {
  fill: var(--blue);
}
.cards-workspace .card-display .card-metadata .add-card {
  position: absolute;
  right: 1em;
  top: 1em;
}
.cards-workspace .card-display .card-metadata .add-card button {
  background: var(--blue);
  color: var(--link-grey);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.cards-workspace .card-display .card-metadata .add-card button:hover {
  color: var(--main-bg);
  background: var(--link-grey);
}
.cards-workspace .card-display .card-metadata select {
  color: #005fa5;
  border: none;
  background: var(--main-bg);
  font-family: monospace;
  margin: 0.4em 0.4em;
}
.cards-workspace .card-display .card-metadata label {
  font-weight: 700;
  margin-right: 1em;
  color: var(--link-grey);
}
.cards-workspace .card-display .card-metadata input {
  font-family: monospace;
  background: none;
  color: #c9beff;
  border: none;
  width: 80%;
  outline: none;
}
.cards-workspace .card-display .card {
  grid-column: 2;
  grid-row: 2;
  width: 100%;
  border-radius: 10px;
  padding: 1em;
  box-sizing: border-box;
}
.cards-workspace .card-display .card .QA {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}
.cards-workspace .card-display .card .QA .img-container {
  position: absolute;
  bottom: 0;
  right: 0;
}
.cards-workspace .card-display .card .QA .img-container img {
  max-width: 18em;
}
.cards-workspace .card-display .card .QA textarea {
  width: 100%;
  resize: none;
  height: 1.2em;
  padding: 0 2em;
  background: none;
  border: none;
  max-height: 30%;
  outline: none;
  text-align: center;
  color: var(--link-grey);
}

