.notebook {
  left: 0;
  right: 0;
  top: 3em;
  bottom: 0;
  position: absolute;
  overflow: hidden;
  border-top: 1px solid var(--border-grey);
}
.notebook .horizontal-menu {
  border-bottom: 1px solid var(--border-grey);
}

